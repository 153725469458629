import React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import DateFilter from "../../../components/DateFilter";
import { KeyboardArrowDown } from "@mui/icons-material";
export default function BookingFilterMobile({
  inputfilter,
  setInputFilter,
  boolOptions,
  propertyTypeList,
  propertyTypeFilter,
  setPropertyTypeFilter,
  keyCollectionPeriodList,
  keyCollectionPeriodFilter,
  setKeyCollectionPeriodFilter,
  paxNumberList,
  paxNumberFilter,
  setPaxNumberFilter,
  productTypeList,
  productTypeFilter,
  setProductTypeFilter,
  stores,
  storeFilter,
  setStoreFilter,
  groupBuyEvents,
  pdpaFilter,
  setPdpaFilter,
  waitingListFilter,
  setWaitingListFilter,
  purchasedFilter,
  setPurchasedFilter,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Autocomplete
            id={"property-type-filter"}
            options={propertyTypeList}
            getOptionLabel={(item) => item.option}
            value={propertyTypeFilter}
            onChange={(e, newValue) => setPropertyTypeFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Property Type"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"key-collection-period-filter"}
            options={keyCollectionPeriodList}
            getOptionLabel={(item) => item.option}
            value={keyCollectionPeriodFilter}
            onChange={(e, newValue) => setKeyCollectionPeriodFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Key Collection Period"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"pax-number-filter"}
            options={paxNumberList}
            getOptionLabel={(item) => item.option}
            value={paxNumberFilter}
            onChange={(e, newValue) => setPaxNumberFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"PAX Number"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"product-type-filter"}
            options={productTypeList}
            getOptionLabel={(item) => item.option}
            value={productTypeFilter}
            onChange={(e, newValue) => setProductTypeFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Product Type"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"store-filter"}
            options={stores}
            getOptionLabel={(item) => item.name}
            value={storeFilter}
            onChange={(e, newValue) => setStoreFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Preferred Outlet"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"pdpa-filter"}
            options={boolOptions}
            getOptionLabel={(item) => item.toUpperCase()}
            value={pdpaFilter}
            onChange={(e, newValue) => setPdpaFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"PDPA Consent"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"waiting-list-filter"}
            options={boolOptions}
            getOptionLabel={(item) => item.toUpperCase()}
            value={waitingListFilter}
            onChange={(e, newValue) => setWaitingListFilter(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Waiting List"}
                variant={"outlined"}
              />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"purchased-filter"}
            options={boolOptions}
            getOptionLabel={(item) => item.toUpperCase()}
            value={purchasedFilter}
            onChange={(e, newValue) => setPurchasedFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Purchased"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        {groupBuyEvents.map((item) => (
          <>
            <Grid item xs={12}>
              <DateFilter
                label={`Appointment Date - From (${item.id})`}
                value={inputfilter?.[item.id]?.["appointmentStartDate"]}
                onChange={(newValue) =>
                  setInputFilter((prev) => ({
                    ...prev,
                    [item.id]: {
                      ...prev?.[item.id],
                      appointmentStartDate: newValue,
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <DateFilter
                label={`Appointment Date - To (${item.id})`}
                value={inputfilter?.[item.id]?.["appointmentEndDate"]}
                onChange={(newValue) =>
                  setInputFilter((prev) => ({
                    ...prev,
                    [item.id]: {
                      ...prev?.[item.id],
                      appointmentEndDate: newValue,
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id={"confirmed-filter"}
                options={boolOptions}
                getOptionLabel={(option) => option.toUpperCase()}
                value={inputfilter?.[item.id]?.["isReminderConfirmed"]}
                onChange={(e, newValue) =>
                  setInputFilter((prev) => ({
                    ...prev,
                    [item.id]: {
                      ...prev?.[item.id],
                      isReminderConfirmed: newValue,
                    },
                  }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Confirmed (${item.id})`}
                    variant={"outlined"}
                  />
                )}
                popupIcon={<KeyboardArrowDown />}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id={"visited-filter"}
                options={boolOptions}
                getOptionLabel={(option) => option.toUpperCase()}
                value={inputfilter?.[item.id]?.["visited"]}
                onChange={(e, newValue) =>
                  setInputFilter((prev) => ({
                    ...prev,
                    [item.id]: {
                      ...prev?.[item.id],
                      visited: newValue,
                    },
                  }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Visited (${item.id})`}
                    variant={"outlined"}
                  />
                )}
                popupIcon={<KeyboardArrowDown />}
              />
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
}
