import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Button,
  Stack,
  Box,
  Typography,
  Container,
  Divider,
  Chip,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QrScanner from "qr-scanner";
import "./style/index.css";
import dayjs from "dayjs";

const GroupBuyAttendanceDashboard = (props) => {
  const {
    isLoading,
    sendRequest,
    httpRequestError: error,
    responseData,
  } = useHttpRequest();
  const { auth } = useRoleAuthorization();
  const [groupBuyEvent, setGroupBuyEvent] = useState();
  const { id } = useParams();
  const [scannedBooking, setScannedBooking] = useState();

  const fetchEvent = async (id) => {
    const { data } = await sendRequest(
      `/v1/group-buy-event/source/${id}`,
      "GET",
    );
    setGroupBuyEvent(data);
  };

  const scanBooking = async (bookingId) => {
    if (!groupBuyEvent || !bookingId) return;

    const {
      data: { booking, isNewVisited },
    } = await sendRequest(
      `/v1/group-buy-attendance/${bookingId}/visited/${groupBuyEvent.id}`,
      "PUT",
    );

    const msg = isNewVisited
      ? `Customer booking ${booking.bookingNo} found and successfully checked in.`
      : `Customer with booking ${
          booking.bookingNo
        } has already attended the event on ${dayjs(
          booking.event[groupBuyEvent.id].visitedAt,
        ).format("DD/MM/YYYY HH:mm")}`;

    Swal.fire({
      icon: "success",
      title: "Success",
      text: msg,
    });

    setScannedBooking(booking);
  };

  useEffect(() => {
    document.title = "Group Buy Attendance";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.group_buy_attendance,
        PrivilegeActions.edit,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    if (id) fetchEvent(id);
  }, [id]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // ********
  // QR States

  const [qrOn, setQrOn] = useState(false);
  const videoElementRef = useRef();
  const [scannedText, setScannedText] = useState();
  const [qrScanner, setQrScanner] = useState();

  const onScanSuccess = async (result) => {
    if (result && result.data) {
      setScannedText(result.data);
    }
  };

  const initQrScanner = () => {
    const video = videoElementRef.current;
    const _qrScanner = new QrScanner(video, onScanSuccess, {
      returnDetailedScanResult: true,
      highlightScanRegion: true,
      highlightCodeOutline: true,
    });
    setQrScanner(_qrScanner);
    return _qrScanner;
  };

  const toggleQR = async (on) => {
    setQrOn(on);

    if (on) {
      const _qrScanner = qrScanner ?? initQrScanner();
      setScannedText(null);
      setScannedBooking(null);
      await _qrScanner.start();
    } else if (qrScanner) {
      qrScanner.stop();
      qrScanner.destroy();
      setQrScanner(null);
    }
  };

  useEffect(() => {
    if (scannedText) {
      toggleQR(false);
      scanBooking(scannedText);
    }
  }, [scannedText]);

  return (
    <>
      {isLoading ? (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      ) : (
        groupBuyEvent && (
          <>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant={"h1"}
                >{`Attendance for ${groupBuyEvent.name}`}</Typography>
              </Grid>
              <Grid item xs={12} md={6} className={"action"}>
                <Button
                  className={"primary"}
                  onClick={() => toggleQR(!qrOn)}
                  startIcon={<QrCodeScannerIcon />}
                >
                  {scannedBooking
                    ? "Scan QR Code"
                    : qrOn
                    ? "Close Scanner"
                    : "Scan QR Code"}
                </Button>
              </Grid>
              <Grid item xs={12} style={{ display: qrOn ? "block" : "none" }}>
                <div className={"qr-reader"}>
                  <video className="qr-video" ref={videoElementRef}></video>
                </div>
              </Grid>
              {scannedBooking && (
                <>
                  <Grid item xs={12}>
                    {/* <Container maxWidth="md"> */}
                    <Grid container className={"form display"}>
                      <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            <Typography variant="h2">{`${groupBuyEvent.name} #${scannedBooking.bookingNo}`}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                              <Grid item xs={12} md={4}>
                                <List>
                                  <ListItem>
                                    <ListItemText
                                      primary={`Booking No.`}
                                      secondary={scannedBooking.bookingNo}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText
                                      primary={`Customer Name`}
                                      secondary={scannedBooking.customerName}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText
                                      primary={`Email`}
                                      secondary={scannedBooking.customerEmail}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText
                                      primary="Mobile No."
                                      secondary={scannedBooking.customerMobile}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <List>
                                  <ListItem>
                                    <ListItemText
                                      primary="Booking Date"
                                      secondary={dayjs(
                                        scannedBooking.createdAt,
                                      ).format("DD/MM/YYYY")}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText
                                      primary="Appointment Date"
                                      secondary={dayjs(
                                        scannedBooking.event[
                                          `${groupBuyEvent.id}`
                                        ].appointmentDate,
                                      ).format("DD/MM/YYYY")}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText
                                      primary="Appointment Time"
                                      secondary={`${
                                        scannedBooking.event[
                                          `${groupBuyEvent.id}`
                                        ].appointmentStartTime
                                      } - ${
                                        scannedBooking.event[
                                          `${groupBuyEvent.id}`
                                        ].appointmentEndTime
                                      }`}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemText
                                      primary="Status"
                                      secondary={`
                                        ${
                                          scannedBooking.event[
                                            `${groupBuyEvent.id}`
                                          ].visited
                                            ? "VISITED"
                                            : "NOT VISITED"
                                        } @ ${dayjs(
                                        scannedBooking.event[
                                          `${groupBuyEvent.id}`
                                        ].visitedAt,
                                      ).format("DD/MM/YYYY HH:mm")}`}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </Container> */}
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )
      )}
    </>
  );
};

export default withRouter(GroupBuyAttendanceDashboard);

function BookingContentRow(props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        {props.label}
      </Grid>
      <Grid item xs={8}>
        <Typography textAlign={"end"}>{props.content}</Typography>
      </Grid>
    </Grid>
  );
}
