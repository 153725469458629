import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
function JsonView({ label, data }) {
  return (
    <Stack gap={1}>
      <Typography variant="h3">{label}</Typography>
      <ReactJson
        src={data}
        theme={"apathy:inverted"}
        style={{ padding: 18, overflow: "scroll", maxHeight: 300 }}
        collapsed={true}
      />
    </Stack>
  );
}
export default function HistoryLogDrawer({ history }) {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} className="display">
      <Grid item xs={12}>
        <JsonView label={"Input Data"} data={history.data.newData} />
      </Grid>
      {history.data.oldData && (
        <Grid item xs={12}>
          <JsonView label={"Previous Data"} data={history.data.oldData} />
        </Grid>
      )}
    </Grid>
  );
}
