import moment from "moment";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const bookingHistoryTableColumnConfig = ({ openHistoryLogDrawer }) => [
  {
    name: "",
    width: "40px",
    selector: (row) => (
      <IconButton>
        <SearchIcon
          onClick={() => {
            openHistoryLogDrawer(row);
          }}
        />
      </IconButton>
    ),
  },
  {
    name: "Recorded Date",
    width: "150px",
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
  },
  {
    name: "Action",
    width: "150px",
    selector: (row) => row.actionType,
  },
  {
    name: "Action By",
    minWidth: "250px",
    selector: (row) =>
      row.userDetails.type === "customer"
        ? `Customer: ${row.userDetails.name}-${row.userDetails.phoneNumber}`
        : `${row.userDetails.name}-${row.userDetails.username}`,
  },
];
