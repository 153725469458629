import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { Settings as EditIcon } from "@mui/icons-material";

export const bookingTableColumnConfig = (
  permission,
  propertyTypeList = [],
  keyCollectionPeriodList = [],
  productTypeList = [],
  paxNumberList = [],
  groupBuyEvents = [],
) => [
  {
    name: "Booking No.",
    wrap: true,
    width: "100px",
    selector: (row) => row.bookingNo,
    sortable: true,
    sortField: "bookingNo",
    cell: (row) => {
      if (permission.allowedViewDetail) {
        return (
          <Link to={"/app/group-buy-booking/card/" + row._id}>
            {row.bookingNo}
          </Link>
        );
      } else {
        return <>{row.bookingNo}</>;
      }
    },
  },
  {
    name: "Booking Date",
    width: "150px",
    wrap: true,
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    sortable: true,
    sortField: "createdAt",
  },
  {
    name: "Customer Name",
    width: "250px",
    wrap: true,
    selector: (row) => row.customerName,
    sortable: true,
    sortField: "customerName",
  },
  {
    name: "Email",
    width: "250px",
    selector: (row) => row.customerEmail,
    sortable: true,
    sortField: "customerEmail",
  },
  {
    name: "Mobile No.",
    wrap: true,
    width: "90px",
    selector: (row) => row.customerMobile,
    sortable: true,
    sortField: "customerMobile",
  },
  {
    name: "Waiting List",
    wrap: true,
    width: "110px",
    selector: (row) => (row?.isWaitingList ? "YES" : "NO"),
    sortable: true,
    sortField: "isWaitingList",
  },
  {
    name: "Purchased",
    wrap: true,
    width: "110px",
    selector: (row) => (row?.purchased ? "YES" : "NO"),
    sortable: true,
    sortField: "purchased",
  },
  ...groupBuyEvents
    .map((item, index) => {
      const obj = [
        {
          name: `Appointment Date (${item.id})`,
          wrap: true,
          width: "150px",
          selector: (row) =>
            row?.event?.[item.id]?.appointmentDate
              ? moment(row?.event?.[item.id]?.appointmentDate).format(
                  "DD/MM/YYYY",
                )
              : "-",
          sortable: true,
          sortField: `event.${item.id}.appointmentDate`,
        },
        {
          name: `Appointment Time (${item.id})`,
          wrap: true,
          width: "150px",
          selector: (row) =>
            row?.event?.[item.id]?.appointmentStartTime
              ? `${row?.event?.[item.id]?.appointmentStartTime} - ${
                  row?.event?.[item.id]?.appointmentEndTime
                }`
              : "-",
          sortable: true,
          sortField: `event.${item.id}.appointmentStartTime`,
        },
        {
          name: `Confirmed (${item.id})`,
          wrap: true,
          center: true,
          width: "90px",
          selector: (row) =>
            typeof row?.event?.[item.id]?.isReminderConfirmed !== "undefined"
              ? row?.event?.[item.id]?.isReminderConfirmed
                ? "YES"
                : "NO"
              : "-",
          sortable: true,
          sortField: `event.${item.id}.isReminderConfirmed`,
        },
        {
          name: `Confirmed Date (${item.id})`,
          wrap: true,
          width: "120px",
          selector: (row) =>
            row?.event?.[item.id]?.isReminderConfirmedAt
              ? moment(row?.event?.[item.id]?.isReminderConfirmedAt).format(
                  "DD/MM/YYYY",
                )
              : "-",
          sortable: true,
          sortField: `event.${item.id}.isReminderConfirmedAt`,
        },
        {
          name: `Visited (${item.id})`,
          wrap: true,
          center: true,
          width: "90px",
          selector: (row) =>
            typeof row?.event?.[item.id]?.visited !== "undefined"
              ? row?.event?.[item.id]?.visited
                ? "YES"
                : "NO"
              : "-",
          sortable: true,
          sortField: `event.${item.id}.visited`,
        },
        {
          name: `Visited Date (${item.id})`,
          wrap: true,
          width: "120px",
          selector: (row) =>
            row?.event?.[item.id]?.visitedAt
              ? moment(row?.event?.[item.id]?.visitedAt).format("DD/MM/YYYY")
              : "-",
          sortable: true,
          sortField: `event.${item.id}.visitedAt`,
        },
      ];
      return obj;
    })
    .flatMap((element) => element)
    .flat(2),
  {
    name: "Property Type",
    wrap: true,
    width: "200px",
    selector: (row) =>
      propertyTypeList.find((item) => item.id === row.propertyTypeId)?.option,
    sortable: true,
    sortField: "propertyTypeId",
  },
  {
    name: "Estate Name",
    wrap: true,
    width: "250px",
    selector: (row) => row.estateName,
    sortable: true,
    sortField: "estateName",
  },
  {
    name: "Key Collection Period",
    wrap: true,
    width: "150px",
    selector: (row) =>
      keyCollectionPeriodList.find(
        (item) => item.id === row.keyCollectionPeriodId,
      )?.option,
    sortable: true,
    sortField: "keyCollectionPeriodId",
  },
  {
    name: "No. of PAX",
    wrap: true,
    right: true,
    width: "90px",
    selector: (row) =>
      paxNumberList.find((item) => item.id === row.paxNumberId)?.option,
    sortable: true,
    sortField: "paxNumberId",
  },
  {
    name: "PDPA Consent",
    wrap: true,
    center: true,
    width: "110px",
    selector: (row) => (row?.pdpa ? "YES" : "NO"),
    sortable: true,
    sortField: "pdpa",
  },
  {
    name: "Preferred Outlet",
    wrap: true,
    width: "300px",
    selector: (row) => row.calendar?.store?.name,
    sortable: true,
    sortField: "calendar.store.name",
  },
  {
    name: "Product Type",
    wrap: true,
    width: "750px",
    selector: (row) =>
      productTypeList
        .filter((item) => row.productTypeIds?.includes(item.id))
        .map((i) => i.option)
        .join(),
    sortField: "productTypeIds",
  },
  {
    name: "UTM Source",
    wrap: true,
    width: "150px",
    selector: (row) => row?.utmSource,
    sortable: true,
    sortField: "utmSource",
  },
  {
    name: "UTM Campaign",
    wrap: true,
    width: "300px",
    selector: (row) => row?.utmCampaign,
    sortable: true,
    sortField: "utmCampaign",
  },
  {
    name: "UTM Medium",
    wrap: true,
    width: "300px",
    selector: (row) => row?.utmMedium,
    sortable: true,
    sortField: "utmMedium",
  },
  {
    name: "UTM Content",
    wrap: true,
    width: "300px",
    selector: (row) => row?.utmContent,
    sortable: true,
    sortField: "utmContent",
  },
  {
    name: "UTM Term",
    wrap: true,
    width: "300px",
    selector: (row) => row?.utmTerm,
    sortable: true,
    sortField: "utmTerm",
  },
];
