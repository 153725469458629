import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Autocomplete,
  Switch,
  InputAdornment,
  IconButton,
  Checkbox,
  Stack,
} from "@mui/material";
import Swal from "sweetalert2";
import "./style/index.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import { Clear, KeyboardArrowDown } from "@mui/icons-material";
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { convertSlotId } from "./utils";
import { Clear as ClearIcon } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { bookingHistoryTableColumnConfig } from "./data-table/booking-history-column-config";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import HistoryLogDrawer from "./components/HistoryLogDrawer";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const BookingCard = (props) => {
  const { auth } = useRoleAuthorization();
  const { isLoading, sendRequest } = useHttpRequest();
  const [currentBookingItem, setCurrentBookingItem] = useState(); // current booking object for edit
  const [calendarList, setCalendarList] = useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [keyCollectionPeriodList, setKeyCollectionPeriodList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [paxNumberList, setPaxNumberList] = useState([]);
  const [propertyTypeSelected, setPropertyTypeSelected] = useState();
  const [availableSlotList, setAvailableSlotList] = useState();
  const [groupBuyEvents, setGroupBuyEvents] = useState([]);
  const [keyCollectionPeriodSelected, setKeyCollectionPeriodSelected] =
    useState();
  const [productTypesSelected, setProductTypesSelected] = useState();
  const [paxNumberSelected, setPaxNumberSelected] = useState();
  const { id } = useParams();
  const historyColumns = bookingHistoryTableColumnConfig({
    openHistoryLogDrawer,
  });
  const methods = useForm({ mode: "all", defaultValues: currentBookingItem });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    setValue,
    reset,
    getValues,
    watch,
  } = methods;
  const [refresh, setRefresh] = useState(true);
  const [bookingHistoryRecords, setBookingHistoryRecords] = useState([]);
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();
  const smallDeviceWidth = 1200;

  // ***********Functions**********
  const handleAction = async (bookingData, type, eventId) => {
    try {
      const successMessage = {
        visited: `Booking ${bookingData.bookingNo} status has been changed to visited`,
        deleted: `Booking ${bookingData.bookingNo} deleted successfully.`,
        reschedule: `Booking ${bookingData.bookingNo} sent reschedule successfully.`,
        purchased: `Booking ${bookingData.bookingNo} purchased successfully.`,
      };

      switch (type) {
        case "visited":
          await sendRequest(`/v1/group-buy-booking/visited`, "PUT", [
            {
              bookingNo: bookingData.bookingNo,
              eventId,
            },
          ]);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: successMessage[type],
            willClose: setRefresh(true),
          });
          break;
        case "deleted":
          await sendRequest(`/v1/group-buy-booking/` + id, "DELETE");
          Swal.fire({
            icon: "success",
            title: "Success",
            text: successMessage[type],
            willClose: navigateToList,
          });
          break;
        case "reschedule":
          await sendRequest(
            `/v1/group-buy-booking/${id}/send-reschedule/${eventId}`,
            "POST",
          );
          Swal.fire({
            icon: "success",
            title: "Success",
            text: successMessage[type],
            willClose: setRefresh(true),
          });
          break;
        case "purchased":
          await sendRequest(`/v1/group-buy-booking/${id}/purchased`, "POST");
          Swal.fire({
            icon: "success",
            title: "Success",
            text: successMessage[type],
            willClose: setRefresh(true),
          });
          break;

        default:
          break;
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.message || "An unknown error occurred.",
      });
    }
  };

  const handleOpenDialog = (type, event) => {
    const confirmationMessage = {
      visited: `Are you sure you want to change the group buy booking ${currentBookingItem.bookingNo} status to visited?`,
      deleted: `Are you sure want to delete the group buy booking ${currentBookingItem.bookingNo}?`,
      reschedule: `Are you sure you want to send reschedule email to customer?`,
      purchased: `Are you sure want to purchase the group buy booking ${currentBookingItem.bookingNo}?`,
    };

    const confirmationSettings = {
      title: "Confirmation",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    };

    Swal.fire({
      ...confirmationSettings,
      text: confirmationMessage[type],
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleAction(currentBookingItem, type, event);
      }
    });
  };

  const mapEvent = (event) => {
    const obj = {};

    Object.entries(event).forEach(([key, value]) => {
      if (value.appointmentDate && value.appointmentTime) {
        value.appointmentDate = dayjs(value.appointmentDate).toDate();
        obj[key] = {
          appointmentDate: value.appointmentDate,
          appointmentStartTime: value.appointmentTime.startTime,
          appointmentEndTime: value.appointmentTime.endTime,
          visited: value.visited,
          slotId: convertSlotId(value),
        };
      }
    });

    return obj;
  };

  const mapData = (data) => {
    return {
      ...data,
      event: mapEvent(data.event),
      keyCollectionPeriodId: data.keyCollectionPeriod.id,
      paxNumberId: data.paxNumber.id,
      productTypeIds: data.productTypes.map((item) => item.id),
      propertyTypeId: data.propertyType.id,
      calendar: data.calendar,
      estateName: data.estateName.trim().toUpperCase(),
      customerName: data.customerName.trim().toUpperCase(),
      customerEmail: data.customerEmail.trim().toUpperCase(),
      pdpa: data.pdpa,
      isWaitingList: data.isWaitingList,
    };
  };

  // form submit
  const handleSubmitForm = async (data) => {
    try {
      let isEventExist = false;
      for (let key in data.event) {
        if (
          data.event[key].appointmentDate &&
          !data.event[key].appointmentTime
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please select an appointment time for the selected appointment date.",
          });
          return;
        } else if (
          data.event[key].appointmentDate &&
          data.event[key].appointmentTime
        ) {
          isEventExist = true;
        }
      }

      if (!isEventExist) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "At least one event must be added.",
        });
        return;
      }

      const payload = {
        ...mapData(data),
      };

      if (id) {
        const { data } = await sendRequest(
          `/v1/group-buy-booking/${id}`,
          "PUT",
          payload,
        );
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Group buy booking ${currentBookingItem.bookingNo} has been updated.`,
          willClose: navigateToList,
        });
      } else {
        const { data } = await sendRequest(
          `/v1/group-buy-booking`,
          "POST",
          payload,
        );
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `New group buy booking ${data.bookingNo} has been created.`,
          willClose: navigateToList,
        });
      }

      return;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.response?.data?.message || "An unknown error occurred.",
      });
      return;
    }
  };

  // navigate back to material list
  const navigateToList = () => {
    window.location.href = "#/app/group-buy-booking/list";
  };

  const fetchCalendar = async () => {
    try {
      const filters = {
        enabled: "Yes",
      };
      const { data } = await sendRequest(
        `/v1/group-buy-calendar/filter?filters=${JSON.stringify(filters)}`,
        "GET",
        {},
      );
      setCalendarList(data.calendars);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
      return;
    }
  };

  const fetchAvailableSlotList = async (
    calendarId,
    appointmentDate,
    eventId,
  ) => {
    try {
      const { data } = await sendRequest(
        `/v1/group-buy-calendar/available-slot?calendarId=${calendarId}&appointmentDate=${appointmentDate}&eventId=${eventId}`,
        "GET",
        {},
      );
      setAvailableSlotList((prev) => ({
        ...prev,
        [eventId]: data.slotList || [],
      }));
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
      return;
    }
  };

  const getBookingById = async (id) => {
    try {
      const { data } = await sendRequest(`/v1/group-buy-booking/` + id, "GET");
      setCurrentBookingItem(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
      return;
    }
  };

  const fetchAttribute = async () => {
    try {
      const { data } = await sendRequest(
        `/v1/group-buy-attribute/attribute`,
        "GET",
      );
      setPropertyTypeList(
        data.propertyType.filter((item) => item.enabled === true),
      );
      setKeyCollectionPeriodList(
        data.keyCollectionPeriod.filter((item) => item.enabled === true),
      );
      setProductTypeList(
        data.productType.filter((item) => item.enabled === true),
      );
      setPaxNumberList(data.paxNumber.filter((item) => item.enabled === true));
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
      return;
    }
  };

  const fetchEvents = async () => {
    const { data } = await sendRequest(`/v1/group-buy-event`, "GET");
    setGroupBuyEvents(data);
  };

  const fetchBookingHistories = async (bookingId) => {
    try {
      const { data } = await sendRequest(
        `/v1/group-buy-history/booking/${bookingId}`,
        "GET",
      );

      setBookingHistoryRecords(data);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err?.message || "An unknown error occurred.",
      });
    }
  };

  function openHistoryLogDrawer(historyRecord) {
    loadActivityContentDrawer(historyRecord, true, false);
    if (!layoutState.isRightbarOpened) {
      toggleRightbar(layoutDispatch);
    }
  }

  function loadActivityContentDrawer(
    historyRecord,
    backButton,
    showBackDropInLargeScreen,
  ) {
    const size = width <= smallDeviceWidth ? "xs" : "lg";
    setRighbarContent(
      layoutDispatch,
      [<HistoryLogDrawer key={"rb-body"} history={historyRecord} />],
      { size, backButton, showBackDropInLargeScreen },
    );
  }

  // redirect to default page if not authorized
  useEffect(() => {
    if (
      id &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.group_buy_booking,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    document.title = "New - Group Buy Booking Card";

    if (currentBookingItem) {
      document.title = `${currentBookingItem.bookingNo} - Group Buy Booking Card`;
      const keyCollectionPeriod = keyCollectionPeriodList.find(
        (list) =>
          list.id === currentBookingItem.keyCollectionPeriodId &&
          list.enabled === true,
      );
      const propertyType = propertyTypeList.find(
        (list) =>
          list.id === currentBookingItem.propertyTypeId &&
          list.enabled === true,
      );
      const productTypes = productTypeList.filter(
        (list) =>
          currentBookingItem.productTypeIds?.includes(list.id) &&
          list.enabled === true,
      );
      const paxNumber = paxNumberList.find(
        (list) =>
          list.id === currentBookingItem.paxNumberId && list.enabled === true,
      );
      const calendar = calendarList.find(
        (list) => list._id === currentBookingItem.calendar,
      );

      setPropertyTypeSelected(propertyType);
      setKeyCollectionPeriodSelected(keyCollectionPeriod);
      setProductTypesSelected(productTypes);
      setPaxNumberSelected(paxNumber);

      for (const key in currentBookingItem.event) {
        fetchAvailableSlotList(
          currentBookingItem.calendar,
          dayjs(currentBookingItem.event[key].appointmentDate).toDate(),
          key,
        );
      }

      reset({
        estateName: currentBookingItem.estateName.toUpperCase(),
        customerName: currentBookingItem.customerName.toUpperCase(),
        customerEmail: currentBookingItem.customerEmail.toUpperCase(),
        customerMobile: currentBookingItem.customerMobile,
        pdpa: currentBookingItem?.pdpa,
        isWaitingList: currentBookingItem.isWaitingList || false,
        event: groupBuyEvents.reduce((acc, item) => {
          acc[item.id] = currentBookingItem?.event?.[item.id]
            ? {
                appointmentDate: dayjs(
                  currentBookingItem.event[item.id].appointmentDate,
                ),
                appointmentTime: {
                  startTime:
                    currentBookingItem.event[item.id].appointmentStartTime,
                  endTime: currentBookingItem.event[item.id].appointmentEndTime,
                },
                visited: currentBookingItem.event[item.id].visited,
                isReminderConfirmed:
                  currentBookingItem.event[item.id].isReminderConfirmed,
              }
            : null;

          return acc;
        }, {}),
        keyCollectionPeriod,
        propertyType,
        paxNumber,
        productTypes,
        calendar,
      });
    }
  }, [
    currentBookingItem,
    keyCollectionPeriodList,
    calendarList,
    productTypeList,
    propertyTypeList,
    paxNumberList,
    groupBuyEvents,
  ]);

  useEffect(() => {
    if (refresh) {
      fetchCalendar();
      fetchAttribute();
      fetchEvents();
      if (id) {
        getBookingById(id);
        fetchBookingHistories(id);
      }
      setRefresh(false);
    }
  }, [id, refresh]);

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                Group Buy Booking Card{" "}
                {currentBookingItem
                  ? ` - ${currentBookingItem.bookingNo}`
                  : ` - New`}
              </Typography>
            </Grid>

            {/* Booking Action */}
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button
                onClick={() =>
                  props.history.push("/app/group-buy-booking/list")
                }
              >
                Cancel
              </Button>
              {((!id &&
                auth.checkModulePrivilege(
                  PrivilegeModules.group_buy_booking,
                  PrivilegeActions.add,
                )) ||
                (currentBookingItem &&
                  !currentBookingItem.visited &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.group_buy_booking,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  className={"primary"}
                  type={"submit"}
                  disabled={
                    Object.keys(formErrors).length !== 0 ||
                    !propertyTypeSelected ||
                    !keyCollectionPeriodSelected ||
                    !paxNumberSelected
                  }
                >
                  Save
                </Button>
              )}

              {currentBookingItem &&
                auth.checkModulePrivilege(
                  PrivilegeModules.group_buy_booking,
                  PrivilegeActions.edit,
                ) &&
                currentBookingItem.event &&
                Object.keys(currentBookingItem?.event).map((key, index) => {
                  if (
                    (dayjs(
                      currentBookingItem.event[key].appointmentDate,
                    ).isAfter(Date.now(), "date") ||
                      dayjs(
                        currentBookingItem.event[key].appointmentDate,
                      ).isSame(Date.now(), "date")) &&
                    !currentBookingItem.event[key].visited
                  )
                    return (
                      <Button
                        key={index}
                        className={"primary"}
                        onClick={() => handleOpenDialog("visited", key)}
                      >
                        {`Visited (${key})`}
                      </Button>
                    );
                })}

              {currentBookingItem &&
                auth.checkModulePrivilege(
                  PrivilegeModules.group_buy_booking,
                  PrivilegeActions.edit,
                ) &&
                currentBookingItem.event &&
                Object.keys(currentBookingItem?.event).map((key, index) => {
                  if (
                    (dayjs(
                      currentBookingItem.event[key].appointmentDate,
                    ).isAfter(Date.now(), "date") ||
                      dayjs(
                        currentBookingItem.event[key].appointmentDate,
                      ).isSame(Date.now(), "date")) &&
                    !currentBookingItem.event[key].visited
                  )
                    return (
                      <Button
                        key={index}
                        className={"primary"}
                        onClick={() => handleOpenDialog("reschedule", key)}
                      >
                        {`Reschedule (${key})`}
                      </Button>
                    );
                })}

              {currentBookingItem &&
                !currentBookingItem.purchased &&
                auth.checkModulePrivilege(
                  PrivilegeModules.group_buy_booking,
                  PrivilegeActions.purchased,
                ) && (
                  <Button
                    className={"primary"}
                    onClick={() => handleOpenDialog("purchased")}
                  >
                    Purchased
                  </Button>
                )}

              {currentBookingItem &&
                auth.checkModulePrivilege(
                  PrivilegeModules.group_buy_booking,
                  PrivilegeActions.delete,
                ) && (
                  <Button
                    className={"primary"}
                    onClick={() => handleOpenDialog("deleted")}
                  >
                    Delete
                  </Button>
                )}
            </Grid>

            {/* Booking Info */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        {id && currentBookingItem && (
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DesktopDatePicker"]}
                                sx={{
                                  "&.MuiStack-root": {
                                    paddingTop: 0,
                                  },
                                  overflow: "visible",
                                }}
                              >
                                <DesktopDatePicker
                                  label="Booking Date"
                                  value={dayjs(currentBookingItem.createdAt)}
                                  format="DD/MM/YYYY"
                                  disabled={true}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                        )}

                        {/* Customer Name */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"customerName"}
                            label={"Customer Name"}
                            variant={"outlined"}
                            {...register("customerName", {
                              required: {
                                value: true,
                                message: "Customer Name cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.customerName}
                            helperText={formErrors?.customerName?.message}
                          />
                        </Grid>

                        {/* Email */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"email"}
                            label={"Email"}
                            variant={"outlined"}
                            {...register("customerEmail", {
                              required: {
                                value: true,
                                message: "Email cannot be blank.",
                              },
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Email format is invalid.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.customerEmail}
                            helperText={formErrors?.customerEmail?.message}
                          />
                        </Grid>

                        {/* Mobile */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"contactNo"}
                            label={"Mobile No."}
                            variant={"outlined"}
                            type="number"
                            {...register("customerMobile", {
                              required: {
                                value: true,
                                message: "Mobile No. cannot be blank.",
                              },
                              pattern: {
                                value: /^[89][0-9]{7}$/,
                                message: "Mobile No. format is invalid.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.customerMobile}
                            helperText={formErrors?.customerMobile?.message}
                            inputProps={{ minLength: 12, maxLength: 12 }}
                          />
                        </Grid>

                        {/* Property Type */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Controller
                            name={`propertyType`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Property Type cannot be blank.",
                              },
                            }}
                            render={(props) => (
                              <Autocomplete
                                id={"propertyType"}
                                options={propertyTypeList}
                                getOptionLabel={(item) =>
                                  item.option.toUpperCase()
                                }
                                value={propertyTypeSelected || null}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Property Type"}
                                    variant={"outlined"}
                                    error={
                                      !!props?.formState?.errors?.propertyType
                                    }
                                    helperText={
                                      props?.formState?.errors?.propertyType
                                        ?.message
                                    }
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setPropertyTypeSelected(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>

                        {/* Estate Name */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"estateName"}
                            label={"Estate Name"}
                            variant={"outlined"}
                            {...register("estateName", {
                              required: {
                                value: true,
                                message: "Estate Name cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.estateName}
                            helperText={formErrors?.estateName?.message}
                          />
                        </Grid>

                        {/* Key collection period */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Controller
                            name={`keyCollectionPeriod`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message:
                                  "Key Collection Period cannot be blank.",
                              },
                            }}
                            render={(props) => (
                              <Autocomplete
                                id={"keyCollectionPeriod"}
                                options={keyCollectionPeriodList}
                                getOptionLabel={(item) =>
                                  item.option.toUpperCase()
                                }
                                value={keyCollectionPeriodSelected || null}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Key Collection Period"}
                                    variant={"outlined"}
                                    error={
                                      !!props?.formState?.errors
                                        ?.keyCollectionPeriod
                                    }
                                    helperText={
                                      props?.formState?.errors
                                        ?.keyCollectionPeriod?.message
                                    }
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setKeyCollectionPeriodSelected(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>

                        {/* PAX Number */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Controller
                            name={`paxNumber`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "No. of PAX cannot be blank.",
                              },
                            }}
                            render={(props) => (
                              <Autocomplete
                                id={"paxNumber"}
                                options={paxNumberList}
                                getOptionLabel={(item) =>
                                  item.option.toUpperCase()
                                }
                                value={paxNumberSelected || null}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"No. of PAX"}
                                    variant={"outlined"}
                                    error={
                                      !!props?.formState?.errors?.paxNumber
                                    }
                                    helperText={
                                      props?.formState?.errors?.paxNumber
                                        ?.message
                                    }
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setPaxNumberSelected(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>

                        {/* Store */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Controller
                            name={`calendar`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Store cannot be blank.",
                              },
                            }}
                            render={(props) => (
                              <Autocomplete
                                id={"calendar"}
                                options={calendarList}
                                getOptionLabel={(item) =>
                                  item.store.name.toUpperCase()
                                }
                                value={props.field.value || null}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Preferred Outlet"}
                                    variant={"outlined"}
                                    error={!!props?.formState?.errors?.calendar}
                                    helperText={
                                      props?.formState?.errors?.calendar
                                        ?.message
                                    }
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  groupBuyEvents.forEach((item) => {
                                    setValue(
                                      `event.${item.id}.appointmentDate`,
                                      null,
                                    );
                                    setValue(
                                      `event.${item.id}.appointmentTime`,
                                      null,
                                    );
                                  });
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>

                        {/* Product Type */}
                        <Grid item xs={12} xl={9}>
                          <Controller
                            name={`productTypes`}
                            control={control}
                            render={(props) => (
                              <Autocomplete
                                id={"productTypes"}
                                options={productTypeList}
                                getOptionLabel={(item) =>
                                  item.option.toUpperCase()
                                }
                                value={productTypesSelected || []}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Product Type"}
                                    variant={"outlined"}
                                    error={
                                      !!props?.formState?.errors?.productTypes
                                    }
                                    helperText={
                                      props?.formState?.errors?.productTypes
                                        ?.message
                                    }
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setProductTypesSelected(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                                multiple
                              />
                            )}
                          />
                        </Grid>

                        {/* PDPA */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch
                                checked={getValues(`pdpa`) || false}
                                onChange={(e) => {
                                  watch("pdpa");
                                  setValue("pdpa", e.target.checked);
                                }}
                              />
                            }
                            label={`PDPA Consent`}
                          />
                        </Grid>

                        {/* Waiting List */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch
                                checked={getValues(`isWaitingList`) || false}
                                onChange={(e) => {
                                  watch("isWaitingList");
                                  setValue("isWaitingList", e.target.checked);
                                }}
                              />
                            }
                            label={`Waiting List`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Booking Event */}
            {groupBuyEvents.map((item, index) => (
              <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                <Grid container className={"form"}>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                      <Grid item xs={12} className={"sub-action"}>
                        <Typography variant={"h2"}>{`${item.name}`}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          {/* Appointment Date */}
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Controller
                              name={`event.${item.id}.appointmentDate`}
                              control={control}
                              render={(props) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DesktopDatePicker"]}
                                    sx={{
                                      "&.MuiStack-root": {
                                        paddingTop: 0,
                                      },
                                      overflow: "visible",
                                    }}
                                  >
                                    <DesktopDatePicker
                                      label={`Appointment Date (${item.id})`}
                                      value={props.field.value || null}
                                      onChange={(newValue) => {
                                        watch(
                                          `event.${item.id}.appointmentTime`,
                                        );
                                        setValue(
                                          `event.${item.id}.appointmentTime`,
                                          undefined,
                                        );
                                        props?.field.onChange(
                                          dayjs(newValue).toDate(),
                                        );
                                        fetchAvailableSlotList(
                                          getValues("calendar")._id,
                                          dayjs(newValue).toDate(),
                                          item.id,
                                        );
                                      }}
                                      minDate={dayjs(Date.now()).add(
                                        getValues("calendar")?.minDayInterval ||
                                          0,
                                        "day",
                                      )}
                                      maxDate={dayjs(Date.now()).add(
                                        getValues("calendar")?.maxDay +
                                          getValues("calendar")
                                            ?.minDayInterval || 14,
                                        "day",
                                      )}
                                      disablePast
                                      disabled={
                                        currentBookingItem?.event?.[item.id]
                                          ?.visited
                                      }
                                      format="DD/MM/YYYY"
                                      slotProps={{
                                        textField: {
                                          error: false,
                                          disabled:
                                            !getValues("calendar") ||
                                            currentBookingItem?.event?.[item.id]
                                              ?.visited,
                                          InputProps: {
                                            endAdornment: props.field.value && (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={() => {
                                                    props?.field.onChange(null);
                                                    setValue(
                                                      `event.${item.id}.appointmentTime`,
                                                      undefined,
                                                    );
                                                  }}
                                                >
                                                  <ClearIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          },
                                        },
                                        inputAdornment: {
                                          position: "start",
                                        },
                                      }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>

                          {/* Appointment Time */}
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Controller
                              name={`event.${item.id}.appointmentTime`}
                              control={control}
                              render={(props) => (
                                <Autocomplete
                                  id={"appointmentTime"}
                                  options={
                                    availableSlotList?.[item.id]?.map(
                                      (slot) => ({
                                        startTime: slot.slotStartTime,
                                        endTime: slot.slotEndTime,
                                      }),
                                    ) || []
                                  }
                                  getOptionLabel={(item) =>
                                    `${item.startTime} - ${item.endTime}`
                                  }
                                  disabled={
                                    !getValues(
                                      `event.${item.id}.appointmentDate`,
                                    ) ||
                                    currentBookingItem?.event?.[item.id]
                                      ?.visited
                                  }
                                  value={props.field.value || null}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`Appointment Time (${item.id})`}
                                      variant={"outlined"}
                                      error={
                                        !!props?.formState?.errors
                                          ?.appointmentTime
                                      }
                                      helperText={
                                        props?.formState?.errors
                                          ?.appointmentTime?.message
                                      }
                                      autoComplete="off"
                                    />
                                  )}
                                  onChange={(event, data) => {
                                    props?.field.onChange(data);
                                  }}
                                  popupIcon={<KeyboardArrowDown />}
                                  ChipProps={{ deleteIcon: <Clear /> }}
                                />
                              )}
                            />
                          </Grid>

                          {/* Visited */}
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <FormControlLabel
                              labelPlacement="start"
                              control={
                                <Switch
                                  {...register(`event.${item.id}.visited`)}
                                  checked={
                                    getValues(`event.${item.id}.visited`) ||
                                    false
                                  }
                                  readOnly={true}
                                />
                              }
                              label={`Visited (${item.id})`}
                            />
                          </Grid>

                          {/* Confirmed */}
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <FormControlLabel
                              labelPlacement="start"
                              control={
                                <Switch
                                  {...register(
                                    `event.${item.id}.isReminderConfirmed`,
                                  )}
                                  checked={
                                    getValues(
                                      `event.${item.id}.isReminderConfirmed`,
                                    ) || false
                                  }
                                  readOnly={true}
                                />
                              }
                              label={`Confirmed (${item.id})`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            {/* History */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} className={"sub-action"}>
                      <Typography variant={"h2"}>{`History`}</Typography>
                    </Grid>
                    <Grid item xs={12} className={"table"}>
                      <DataTable
                        fixedHeader={true}
                        persistTableHead={true}
                        columns={historyColumns}
                        defaultSortAsc={false}
                        defaultSortFieldId={1}
                        data={bookingHistoryRecords}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default BookingCard;
