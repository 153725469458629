import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Button,
  Stack,
  Box,
} from "@mui/material";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";

const GroupBuyAttendanceDashboard = (props) => {
  const { isLoading, sendRequest } = useHttpRequest();
  const { auth } = useRoleAuthorization();
  const [groupBuyEvents, setGroupBuyEvents] = useState([]);

  useEffect(() => {
    document.title = "Group Buy Attendance";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.group_buy_attendance,
        PrivilegeActions.edit,
      )
    ) {
      props.history.push("/app/dashboard");
    } else {
      fetchEvents();
    }
  }, []);

  const fetchEvents = async () => {
    const { data } = await sendRequest(`/v1/group-buy-event`, "GET");
    setGroupBuyEvents(data);
  };

  const openAttendanceEvent = (source) => {
    props.history.push(`/app/group-buy-attendance/${source}`);
  };

  return (
    <>
      <Stack
        direction={"row"}
        className={"action"}
        sx={{ justifyContent: "center!important" }}
      >
        {groupBuyEvents.map((item, index) => (
          <Box key={index}>
            <Button
              className={"primary"}
              type={"button"}
              onClick={() => openAttendanceEvent(item.source)}
            >
              {item.name}
            </Button>
          </Box>
        ))}
      </Stack>
    </>
  );
};

export default withRouter(GroupBuyAttendanceDashboard);
