export const messageList = [
  {
    id: "success_a",
    label: {
      title: "Success Title-A",
      message: "Success Message-A",
    },
  },
  {
    id: "success_b",
    label: {
      title: "Success Title-B",
      message: "Success Message-B",
    },
  },
  {
    id: "success_c",
    label: {
      title: "Success Title-C",
      message: "Success Message-C",
    },
  },
  {
    id: "success_d",
    label: {
      title: "Success Title-D",
      message: "Success Message-D",
    },
  },
  {
    id: "success_e",
    label: {
      title: "Success Title-E",
      message: "Success Message-E",
    },
  },
  {
    id: "error_a",
    label: {
      title: "Error Title-A",
      message: "Error Message-A",
    },
  },
  {
    id: "error_b",
    label: {
      title: "Error Title-B",
      message: "Error Message-B",
    },
  },
];

export const emailContentList = [
  {
    id: "email_content_a",
    label: "Email Content-A",
  },
  {
    id: "email_content_b",
    label: "Email Content-B",
  },
  {
    id: "email_content_c",
    label: "Email Content-C",
  },
  {
    id: "email_content_d",
    label: "Email Content-D",
  },
];

export const reminderMessageList = [
  {
    id: "reminder_confirmed",
    label: "Confirmed",
  },
];

export const reminderEmailContentList = [
  {
    id: "reminder_email_a",
    label: "Email-A",
  },
];
